import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Typography } from "@mui/material";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";

const NoLessions: React.FC = () => (
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      rowGap: "1.4rem",
    }}
  >
    <FontAwesomeIcon icon={faXmark as IconProp} size="4x" />
    <Typography>Inga tidigare lektioner</Typography>
  </div>
);

export default NoLessions;
