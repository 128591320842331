import React from "react";

import PendingLesson from "components/LessonDisplay/PendingLesson";

function PendingLessonList({ data: pendinglessons, isLoading }) {
  return (
    <div className={`px-3`}>
      {!isLoading &&
        pendinglessons
          ?.map(lesson =>
            lesson?.applications.map(application => (
              <PendingLesson key={lesson.id} lesson={lesson} application={application} />
            )),
          )
          .reduce((acc, curr) => acc.concat(curr), [])}
    </div>
  );
}

export default PendingLessonList;
