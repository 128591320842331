//import NodeGeocoder from 'node-geocoder'
//for NodeGeoCoder --- deprecated now trying codezap.io -- kidding fixed at BE
// const options = {
//     apiKey: 'AIzaSyDpOmk71srDXY9hth_JSMuppGIx2R9-xXo',
//     provider: 'google',
//     language: 'sv',
//     fetch: function(url, options){
//         return fetch(url,{
//             ...options
//         })
//     }
// };
// const parsed = await fetch(
//     `https://api.codezap.io/v1/reverse?lat=${lat}&lng=${lng}`,
//     {method: 'GET', headers:{ 'api-key': codeZapKey} }
//     ).then(r=>r.json());
// console.log(parsed, lat, lng);

//const geocoder = NodeGeocoder(options);

//const codeZapKey = "QKWhKGCuGISADdudrdnvG9ni49OGUqtf"

export const getAddress = async latLng => {
  const { lat, lng } = latLng;
  const filterdLatLng = { lat: latLng.lat, lon: latLng.lng };
  const res = await fetch(`/api/profile/get-address/${lat}/${lng}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
  });
  const parsed = await res.json();
  return parsed?.adress || "Kunde inte hitta address";
  //    return "Bananstigen 69";
  // return geocoder.reverse(filterdLatLng)
  //     .then(r=>{ console.log(r[0]); return `${r[0]?.streetName} ${r[0]?.streetNumber}`});
};

export const getGeoData = async adress => {
  const res = await fetch("/api/profile/get-latlng", {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
    body: JSON.stringify({ adress }),
  });
  if (res.status !== 200) return null;
  const parsed = await res.json();
  if (!parsed) return null;
  return parsed;
  //    return {lat: 69, lng:42.0}
  // return geocoder.geocode(address)
  //     .then(r=>{return {lat: r.latitude, lng: r.longitude}})
  //     .catch(err=>{console.log(`Error with geocoder api ${err}`)});
};
