import { Button, CircularProgress, Typography } from "@mui/material";
import { studentLinks } from "components/navigation/student-navigation";
import { useFetch } from "hooks/fetch-hooks";
import { useNavigate } from "react-router-dom";

import Awaiting from "./awaiting";
import Loading from "components/Loading";
import Pending from "./pending";

import React from "react";
import Scheduled from "./scheduled";
import styles from "./schedule.module.css";

export function StudentAwaiting({ data }) {
  //const data = useFetch("/api/lesson/student/requested", { authorize: true });
  //if (!data?.isLoading === false) return <Loading />;
  //if (data?.data.length === 0) return <Empty />;
  return (
    <section className={`${styles.schedule}`}>
      <Awaiting {...data} />
    </section>
  );
}

export function StudentPending({ data }) {
  return (
    <section className={`${styles.schedule}`}>
      <Pending {...data} />
    </section>
  );
}

export function StudentSchedule({ data }) {
  //const data = useFetch("/api/lesson/student/scheduled", { authorize: true });
  //if (!data?.isLoading === false) return <Loading />;
  //if (data?.data.length === 0) return <Empty />;
  return (
    <section className={`${styles.schedule}`}>
      <Scheduled {...data} />
    </section>
  );
}
