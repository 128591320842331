import React from "react";

import { Typography } from "@mui/material";
import styles from "./styles.module.css";

type PageTitleProps = {
  children: React.ReactNode;
};

const PageTitle: React.FC<PageTitleProps> = ({ children }) => (
  <Typography variant="h4" align="center" sx={{ fontWeight: 500, mb: 4 }} className={styles.title}>
    {children}
  </Typography>
);

export default PageTitle;
