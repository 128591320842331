import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

function BookedInfo() {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ mt: 4 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 4,
          bgcolor: "background.paper",
          boxShadow: 1,
          borderRadius: 2,
        }}
      >
        <Typography component="h1" variant="h4" color="grey" sx={{ mb: 2 }}>
          Tack för din beställning!
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Din order har lagts och en lärare kommer att kontakta dig inom kort. Betalningen sker
          först i detta steg.
          <br />
          <br />
          Lektionen syns under "kommande lektioner" först efter att läraren har godkänt bokningen.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleGoHome}>
          Tillbaka
        </Button>
      </Box>
    </Container>
  );
}

export default BookedInfo;
