import React from "react";

import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Registration from "./Registration";

export function Auth({ setSignedIn, refreshData }) {
  const [page, setPage] = React.useState(0);
  switch (page) {
    case 1:
      return <Registration setPage={setPage} />;
    case 2:
      return <ForgotPassword setPage={setPage} />;
    default:
      return <Login setPage={setPage} setSignedIn={setSignedIn} refreshData={refreshData} />;
  }
}

export default Auth;
