import "./Loading.css";
import React from "react";

// The Loading component is a functional component that displays a loading spinner and message
export const Loading = () => {
  // Initialize the text state, which will be used to display a series of dots that animate to show that the app is still loading
  const [text, changeText] = React.useState(1);

  // Use the useEffect hook to update the text state every 1000ms (1 second)
  React.useEffect(() => {
    // Create an interval that updates the text state every 1000ms
    const interval = setInterval(() => {
      // Update the text state by incrementing the current value and taking the remainder when divided by 4
      // This will cause the text state to cycle between the values 0, 1, 2, and 3
      changeText(text => (text + 1) % 4);
    }, 1000);
    // Return a cleanup function that will clear the interval when the component unmounts
    return () => clearInterval(interval);
  });

  // Render the loading spinner and message
  return (
    <div className="loading">
      <header className="loading-header">
        <p>Loading{".".repeat(text)}</p>
      </header>
    </div>
  );
};

export default Loading;
