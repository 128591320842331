import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { Field, Form } from "react-final-form";
import { NotificationContext } from "index";
import { resetPassword } from "api/user/user";
import { validate, validateRules } from "utils/formValidation";
import LoginLayout from "layouts/LoginLayout";
import React from "react";
import styles from "./ForgotPassword.module.css";

type ForgotPasswordProps = {
  setPage: (page: number) => void;
};

const CustomTextField: React.FC<any> = ({ name, label, type, required = false }) => (
  <Box marginBottom={2}>
    <Field name={name}>
      {({ input, meta }) => (
        <TextField
          {...input}
          error={meta.touched && meta.error}
          helperText={meta.touched && meta.error}
          label={label}
          type={type}
          fullWidth
          variant="standard"
          required={required}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const allowedPattern = validateRules[name]?.allowed;
            if (allowedPattern !== undefined && !allowedPattern.test(event.target.value)) {
              event.preventDefault();
            } else {
              input.onChange(event);
            }
          }}
        />
      )}
    </Field>
  </Box>
);

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ setPage }) => {
  const [loading, setLoading] = React.useState(false);
  const { setNotification } = React.useContext(NotificationContext);

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const res = await resetPassword(values);
    setLoading(false);
    if (!res.success) {
      setNotification({ show: true, message: res.message, type: "error" });
    } else {
      setNotification({ show: true, message: res.message, type: "success" });
      setPage(0);
    }
  };

  return (
    <LoginLayout>
      <div className={styles.root}>
        <Container>
          <div className={styles.inputContainer}>
            <Box marginBottom={2}>
              <Typography variant="h4" color={"gray"}>
                Återställ lösenord
              </Typography>
            </Box>
            <Form
              onSubmit={handleSubmit}
              validate={values => {
                const otherErrors = validate({
                  email: validateRules.email,
                })(values);
                return { ...otherErrors };
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div>
                      <CustomTextField name="email" label="Email" type="email" required />
                    </div>
                    <Box marginTop={5} display="flex" justifyContent="flex-end" columnGap={2}>
                      <Button color="primary" onClick={() => setPage(0)}>
                        Tillbaka
                      </Button>
                      <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        Skicka
                      </Button>
                    </Box>
                  </div>
                </form>
              )}
            />
          </div>
        </Container>
      </div>
    </LoginLayout>
  );
};

export default ForgotPassword;
