import { LoginContext } from "app";
import React from "react";

function TeacherSettings() {
  const { setStudentView } = React.useContext(LoginContext);
  return (
    <div>
      <button className="btn" onClick={() => setStudentView(true)}>
        Byt till student vy
      </button>
    </div>
  );
}

export default TeacherSettings;
