import Loading from "components/Loading";
import React from "react";

const withLoadingScreen = Component => {
  function WithLoading(props) {
    if (Component == null) return <Loading />;
    return <Component {...props} />;
  }
  return WithLoading;
};

export default withLoadingScreen;
