import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { Field, Form } from "react-final-form";
import { NotificationContext } from "index";
import { SignupData, signup } from "api/user/user";
import { validate, validateRules } from "../../../utils/formValidation";
import LoginLayout from "layouts/LoginLayout";
import React from "react";
import styles from "./Registration.module.css";

type RegistrationProps = {
  setPage: (page: number) => void;
};

type CustomTextFieldProps = {
  name: string;
  label: string;
  type: string;
  required?: boolean;
};

const validatePasswordsMatch = values => {
  const errors = {};
  if (values.password !== values.password_repeated) {
    errors["password_repeated"] = "Lösenorden matchar inte";
  }
  return errors;
};

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  name,
  label,
  type,
  required = false,
}) => (
  <Box marginBottom={2}>
    <Field name={name}>
      {({ input, meta }) => (
        <TextField
          {...input}
          error={meta.touched && meta.error}
          helperText={meta.touched && meta.error}
          label={label}
          type={type}
          fullWidth
          variant="standard"
          required={required}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const allowedPattern = validateRules[name]?.allowed;
            if (allowedPattern !== undefined && !allowedPattern.test(event.target.value)) {
              event.preventDefault();
            } else {
              input.onChange(event);
            }
          }}
        />
      )}
    </Field>
  </Box>
);

const Registration: React.FC<RegistrationProps> = ({ setPage }) => {
  const { setNotification } = React.useContext(NotificationContext);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values: SignupData) => {
    setLoading(true);
    const res = await signup(values);
    setLoading(false);
    if (!res.success) {
      setNotification({ show: true, message: res.message, type: "error" });
    } else {
      setNotification({ show: true, message: res.message, type: "success" });
      setPage(0);
    }
  };

  return (
    <LoginLayout>
      <div className={styles.root}>
        <Container>
          <div className={styles.inputContainer}>
            <Box marginBottom={2} alignSelf={"left"}>
              <Typography variant="h4" color="gray">
                Skapa konto
              </Typography>
            </Box>
            <Form
              onSubmit={handleSubmit}
              validate={values => {
                const passwordErrors = validatePasswordsMatch(values);
                const otherErrors = validate(
                  {
                    name: validateRules.name,
                    email: validateRules.email,
                    password: validateRules.password,
                    password_repeated: validateRules.password,
                    phonenumber: validateRules.phonenumber,
                    birthyear: validateRules.birthyear,
                  },
                  { birthyear: true },
                )(values);
                return { ...otherErrors, ...passwordErrors };
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div>
                      <CustomTextField
                        name="name"
                        label="För- och efternamn"
                        type="text"
                        required
                      />
                      <CustomTextField
                        name="phonenumber"
                        label="Telefonnummer"
                        type="tel"
                        required
                      />
                      <CustomTextField name="email" label="Email" type="email" required />
                      <CustomTextField name="password" label="Lösenord" type="password" required />
                      <CustomTextField
                        name="password_repeated"
                        label="Repetera lösenord"
                        type="password"
                        required
                      />
                      <CustomTextField name="default_address" label="Address" type="text" />
                      <CustomTextField name="birthyear" label="Födelseår" type="number" />
                    </div>
                    <Box marginTop={5} display="flex" justifyContent="flex-end" columnGap={2}>
                      <Button color="primary" onClick={() => setPage(0)}>
                        Tillbaka
                      </Button>
                      <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        Registrera
                      </Button>
                    </Box>
                  </div>
                </form>
              )}
            />
          </div>
        </Container>
      </div>
    </LoginLayout>
  );
};

export default Registration;
