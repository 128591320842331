import { Route, Routes } from "react-router-dom";
import React from "react";

import CustomContainer from "components/CustomContainer";
import Flow from "./booking-flow";
import History from "./history";
import Home from "./home";
import Navigation, { teacherLinks } from "components/navigation/teacher-navigation";
import Profile from "./profile";

function Routing() {
  return (
    <>
      <Navigation />
      <Routes>
        <Route
          path={teacherLinks.HOME}
          element={
            <CustomContainer>
              <Home />
            </CustomContainer>
          }
        />
        <Route
          path={teacherLinks.FLOW}
          element={
            <CustomContainer>
              <Flow />
            </CustomContainer>
          }
        />
        <Route
          path={teacherLinks.HISTORY}
          element={
            <CustomContainer>
              <History />
            </CustomContainer>
          }
        />
        <Route
          path={teacherLinks.PROFILE + "/*"}
          element={
            <CustomContainer>
              <Profile />
            </CustomContainer>
          }
        />
      </Routes>
    </>
  );
}

export default Routing;
