import { useNavigate } from "react-router-dom";
import React, { useCallback, useState } from "react";

import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import StarRateIcon from "@mui/icons-material/StarRate";

import "./rate-lesson.css";
import globals from "globalData";

// FIXME: Deprecated with MUI
// const StyledRating = withStyles({
//     iconFilled: {
//       color: 'gold',
//     },
//     iconEmpty: {
//       color: '#888',
//     },
//   })(Rating);

export const CustomRating = ({ readOnly, rating, onChange = () => {}, spacing }) => {
  readOnly = readOnly || false;
  const IconContainer = props => (
    <Box mx={spacing} {...props}>
      <StarRateIcon />
    </Box>
    // <span mx={spacing} {...props}>
    // </span>
  );

  return (
    <Rating
      value={rating * globals.maxRating}
      readOnly={readOnly}
      onChange={(event, newVal) => onChange(newVal / globals.maxRating)}
      IconContainerComponent={IconContainer}
      max={globals.maxRating}
      precision={0.5}
      icon={<StarRateIcon />}
    />
  );
};

function RateLesson({ lessonID, finish }) {
  lessonID = 1;
  //console.log(lessonID.length)
  //const [current_rate, setCurrent_rate] = useState(5)
  const navigate = useNavigate();
  const handleSubmit = useCallback(
    rating => {
      const sendRate = async () => {
        const res = await fetch("/api/lesson/rate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
          body: JSON.stringify({ lessonID, rating }),
        });
      };
      sendRate();
      const done =
        finish ||
        function () {
          navigate("/");
        };
      //done();
    },
    [lessonID],
  );
  const [rating, setRating] = useState(3);
  return (
    <section className="Rate-lesson-section">
      <div className="container text-center">
        <h1 className="my-5 text-center f2">Betygsätt din Lektion</h1>
        <div className="row g-5 d-flex flex-column align-items-center">
          <div className="col-md-8">
            <div className="round-img-parent" style={{ width: "15rem", height: "15rem" }}>
              <img
                src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80"
                alt="Profile"
                className="lying-img"
              />
            </div>
          </div>
          <div className="col-md-8 mb-3">
            <CustomRating rating={rating} onChange={val => setRating(val)} spacing={2} />
          </div>
          <div className="col-md-8 info py-3">
            <p className="f4">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Qui dignissimos sunt
              perferendis architecto, alias excepturi.
            </p>
          </div>
        </div>
        {/* <button className="btn" onClick={handleSubmit}>Skicka</button> */}
      </div>
    </section>
  );
}

export default RateLesson;
