import PageTitle from "components/navigation/PageTitle";
import React from "react";

import { Button, Typography } from "@mui/material";
import { PagesTwoTone } from "@mui/icons-material";
import {
  StudentAwaiting,
  StudentPending,
  StudentSchedule,
} from "components/LessonDisplay/schedule/studentSchedule";
import { TopBarContext } from "student-pages/app";
import { studentLinks } from "components/navigation/student-navigation";
import { useFetch } from "hooks/fetch-hooks";
import { useNavigate } from "react-router-dom";
import Loading from "components/Loading";
import Page from "components/Page";
import styles from "./Home.module.css";

export const HomeTitle = () => <></>;

const Empty = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        rowGap: "0.4rem",
      }}
    >
      <Typography variant="body1">Inga Lektioner?</Typography>
      <Button
        variant="contained"
        size="large"
        onClick={() => {
          navigate(studentLinks.BOOK);
        }}
      >
        Boka nu
      </Button>
    </div>
  );
};

export const Home = () => {
  const { setTopContent } = React.useContext(TopBarContext);
  React.useEffect(() => {
    setTopContent(prev => ({
      ...prev,
      childId: "HomeTitle",
      childProps: {},
      childPos: "bottom",
      flatTop: false,
    }));
    return () => {
      setTopContent(prev => ({
        ...prev,
        childId: null,
        childProps: {},
        childPos: null,
      }));
    };
  }, [setTopContent]);

  const data_scheduled = useFetch("/api/lesson/student/scheduled", { authorize: true });
  const data_pending = useFetch("/api/lesson/student/pending", { authorize: true });
  const data_awaiting = useFetch("/api/lesson/student/awaiting", { authorize: true });
  if (
    data_scheduled?.isLoading === true ||
    data_pending?.isLoading === true ||
    data_awaiting?.isLoading === true
  )
    return <Loading />;
  if (
    data_scheduled?.data?.length === 0 &&
    data_pending?.data?.length === 0 &&
    data_awaiting?.data?.length === 0
  )
    return <Empty />;

  return (
    <Page>
      <PageTitle>Beställda Lektioner</PageTitle>
      <StudentAwaiting data={data_awaiting} />
      <PageTitle>Acceptera Lektioner</PageTitle>
      <StudentPending data={data_pending} />
      <PageTitle>Kommande Lektioner</PageTitle>
      <StudentSchedule data={data_scheduled} />
    </Page>
  );
};

export default Home;
