import { CustomRating } from "components/RateLesson";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";

import { LoginContext } from "app";
import { request_teacher } from "../api-contact";

import styles from "./finished.module.css";

const FinishedLesson = ({ lesson, onClick }) => {
  const { role } = useContext(LoginContext);
  const request_btn = (
    <span
      onClick={e => {
        e.stopPropagation();
        request_teacher(lesson.teacher.id);
      }}
      className="d-flex justify-content-center align-items-center px-2 py-1 accept-theme"
    >
      <FontAwesomeIcon icon={faPlus} />
    </span>
  );
  const {
    profile: { name: ownName },
  } = React.useContext(LoginContext);
  const displayName =
    lesson?.teacher?.name === ownName ? lesson?.student?.name : lesson?.teacher?.name;
  return (
    <article>
      <MDBContainer fluid onClick={onClick} className={styles.info}>
        <MDBRow>
          <MDBCol size="9" className="d-flex justify-content-start align-items-center">
            <div className="round-img-parent mx-2" style={{ width: "10vw", height: "10vw" }}>
              <img
                src="https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80"
                alt="Profile"
                className="lying-img"
              />
            </div>
            <CustomRating rating={lesson.rating || 0} readOnly />
          </MDBCol>
          <MDBCol size="3" className="d-flex justify-content-center align-items-center">
            <p className="f3 cap me-2">{displayName}</p>
          </MDBCol>
          {/* <MDBCol size="3" className="d-flex justify-content-center align-items-center">
                        {role === "student" && request_btn}
                    </MDBCol> */}
        </MDBRow>
      </MDBContainer>
    </article>
  );
};
export default FinishedLesson;
