import { Route, Routes } from "react-router-dom";
import React from "react";

import AddBooking from "./add-booking";
// import Animation from "components/Animation/animation";
import Auth from "components/auth";
import CustomContainer from "components/CustomContainer";
import History from "./history";
import Home from "./home";
import Loading from "components/Loading";
import Login from "components/auth/Login";
import Maps from "components/Maps/experiment";
import Navigation, { studentLinks } from "components/navigation/student-navigation";
import Profile from "./Profile";
import RateLesson from "components/RateLesson";
// import PaymentMethods from "components/Payment/PaymentMethods";
// import Swipe from "components/swipe";
// import SwipeExp from "components/swipe/oldCard/exp";
// import SwishPayment from "components/Payment/Swish";
import TopBar from "components/navigation/topbar";

import wrapperStyles from "./wrapper.module.css";

type TopBarContextType = {
  topContent: any;
  setTopContent: React.Dispatch<React.SetStateAction<any>>;
  helperText: string;
  setHelperText: React.Dispatch<React.SetStateAction<string>>;
  helperTitle: string;
  setHelperTitle: React.Dispatch<React.SetStateAction<string>>;
  useTopPadding: boolean;
  setUseTopPadding: React.Dispatch<React.SetStateAction<boolean>>;
  skipContainer: boolean;
  setSkipContainer: React.Dispatch<React.SetStateAction<boolean>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  totalSteps: number;
  setTotalSteps: React.Dispatch<React.SetStateAction<number>>;
};

export const TopBarContext = React.createContext<TopBarContextType | null>(null);

const App = () => {
  const [topContent, setTopContent] = React.useState<any>(null);
  const [helperText, setHelperText] = React.useState<string>("");
  const [helperTitle, setHelperTitle] = React.useState<string>("");
  const [useTopPadding, setUseTopPadding] = React.useState<boolean>(true);
  const [skipContainer, setSkipContainer] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<number>(0);
  const [totalSteps, setTotalSteps] = React.useState<number>(0);

  const value = {
    topContent,
    setTopContent,
    helperText,
    setHelperText,
    helperTitle,
    setHelperTitle,
    useTopPadding,
    setUseTopPadding,
    skipContainer,
    setSkipContainer,
    step,
    setStep,
    totalSteps,
    setTotalSteps,
  };

  return (
    <TopBarContext.Provider value={value}>
      <div className={wrapperStyles.wrapper}>
        <TopBar />
        <header className={wrapperStyles.content}>
          <div className={wrapperStyles.scrollparent}>
            <Routes>
              <Route
                path={studentLinks.HOME + "/*"}
                element={
                  <CustomContainer skipContainer={skipContainer}>
                    <Home />
                  </CustomContainer>
                }
              />
              <Route path={studentLinks.BOOK + "/*"} element={<AddBooking />} />
              <Route
                path={studentLinks.HISTORY + "/*"}
                element={
                  <CustomContainer>
                    <History />
                  </CustomContainer>
                }
              />
              <Route
                path={studentLinks.PROFILE + "/*"}
                element={
                  <CustomContainer>
                    <Profile />
                  </CustomContainer>
                }
              />

              <Route path="/about" element={<Loading />} />

              {/* For Testing */}
              <Route path="/rate" element={<RateLesson />} />
              <Route path="/maps" element={<Maps />} />

              <Route
                path="/*"
                element={
                  <CustomContainer>
                    <Home />
                  </CustomContainer>
                }
              />
            </Routes>
          </div>
        </header>
        <Navigation />
      </div>

      {/* <Footer/> */}
    </TopBarContext.Provider>
  );
};

export default App;
