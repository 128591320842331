import { Button, Container, Grid, TextField, TextareaAutosize, Typography } from "@mui/material";
import { TopBarContext } from "student-pages/app";
import { dateToSwe } from "services/formats/dates";
import Box from "@mui/material/Box";
import React, { useContext, useEffect } from "react";

import globals from "globalData";

function Confirm({ formData, setFormData, goToNext }) {
  const { setHelperText, setHelperTitle } = useContext(TopBarContext);

  useEffect(() => {
    setHelperTitle("Bekräfta bokningen");
    setHelperText(
      "Detaljer om lektionen. Här kan du se att allt stämmer innan du beställer lektionen.",
    );
    return () => {
      setHelperTitle(null);
      setHelperText(null);
    };
  }, []);

  const handleComment = e => {
    setFormData({
      ...formData,
      note: e.target.value,
    });
  };

  return (
    <section style={{ textAlign: "center" }}>
      <Typography variant="h4" sx={{ py: 2 }}>
        Orderbekräftelse
      </Typography>
      <Container maxWidth="md" sx={{ mt: 2, mb: 4, p: 2, backgroundColor: "#f8f9fa" }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography textAlign="left">Ämnen:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography textAlign="right">{formData.subjects.join(", ")}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography textAlign="left">Datum:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography textAlign="right">{dateToSwe(formData.date)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography textAlign="left">Start Mellan:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography textAlign="right">{`${formData.earliest_potential_start.toLocaleTimeString(
              "sv-SE",
              { hour: "2-digit", minute: "2-digit" },
            )} - ${formData.latest_potential_start.toLocaleTimeString("sv-SE", {
              hour: "2-digit",
              minute: "2-digit",
            })}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography textAlign="left">Plats:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography textAlign="right">{formData.position.address}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography textAlign="left">Längd:</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography textAlign="right">{`${formData.length} min`}</Typography>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ mt: 3, p: 5, backgroundColor: "#f0f0f0" }}>
        <Container maxWidth="sm" sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography textAlign="left">Kommentar:</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                minRows={4}
                fullWidth
                multiline
                placeholder="Kommentar"
                value={formData.note}
                onChange={handleComment}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography textAlign="left">Rabattkod:</Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField variant="standard" fullWidth placeholder="Promo code" />
            </Grid>
            <Grid item xs={4}>
              <Typography textAlign="left">Totalt:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography textAlign="right" color="green">
                {formData.length * globals.price} kr
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Button className="goToNextButton" onClick={goToNext}>
          Bekräfta
        </Button>
      </Box>
    </section>
  );
}

export default Confirm;
