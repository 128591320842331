import { Link, Typography } from "@mui/material";
import LoginLayout from "layouts/LoginLayout";
import Page from "components/Page";
import PageTitle from "components/navigation/PageTitle";
import React from "react";

const AboutUs: React.FC = () => (
  <LoginLayout>
    <Page>
      <PageTitle>Om oss</PageTitle>
      <Typography variant="h5" align="left" paragraph>
        Vad vi gör
      </Typography>
      <Typography variant="body1" align="left" paragraph>
        GradeLift är en plattform som hjälper studenter att lyckas i skolan. Vi erbjuder en rad
        olika tjänster som underlättar studierna och hjälper dig att nå dina mål. Vårt mål är att ge
        dig verktygen du behöver för att lyckas, oavsett vilken nivå du befinner dig på.
      </Typography>
      <Typography variant="body1" align="left" paragraph>
        Med ett engagerat team av kvalificerade lärare och mentorer, erbjuder vi skräddarsydd
        läxhjälp som passar just dina behov. Våra platform kopplar effektivt samman dig med den bäst
        passande läraren för dina specifika kurskrav och studievanor.
      </Typography>
      <Typography variant="body1" align="left" paragraph>
        Vi fokuserar primärt på gymnasieelever och erbjuder hjälp i ett brett utbud av ämnen som
        täcker nästan alla kurser tillgängliga i Sveriges gymnasieskolor. Oavsett om det handlar om
        matematik, naturvetenskap eller språk, har vi resurserna för att stödja din lärande process.
      </Typography>
      <Typography variant="body1" align="left" paragraph>
        Vårt system är både flexibelt och kostnadseffektivt. Du betalar endast för den tid du
        faktiskt spenderar med en lärare, och våra priser är noggrant kalibrerade för att vara
        överkomliga utan att kompromissa med kvaliteten på undervisningen eller ersättningen till
        våra lärare. Vi erbjuder tjänster i Stockholm men har planer på att utöka vår räckvidd för
        att inkludera fler städer eller online-alternativ i framtiden.
      </Typography>
      <Typography variant="h5" align="left" paragraph>
        Vilka vi är
      </Typography>
      <Typography variant="body1" align="left" paragraph>
        GradeLift grundades av tre passionerade ingenjörsstudenter med en gemensam vision om en
        bättre och mer tillgänglig utbildningsplattform. En av oss studerar industriell ekonomi på
        KTH, en annan teknisk fysik på KTH, och den tredje datateknik på LTH. Vår kombinerade
        expertis inom dessa fält ger oss en unik förmåga att förstå och möta de utmaningar som
        studenter står inför idag.
      </Typography>
      <Typography variant="body1" align="left" paragraph>
        Vi är drivna av en övertygelse om att utbildning ska vara tillgänglig, anpassningsbar och
        framför allt prisvärd. Denna övertygelse har lett oss till att själva utveckla en app som
        stödjer vårt mål att skapa en smidig plattform där elevers behov står i fokus.
      </Typography>
    </Page>
  </LoginLayout>
);

export default AboutUs;
