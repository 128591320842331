import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Route, Routes } from "react-router-dom";
import { LoginContext } from "app";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";
//Profile Pages
import Edit from "./edit-profile";
import TeacherSettings from "./settings";

import "./Profile.css";

function ProfileInfo() {
  const { profile } = useContext(LoginContext);
  return (
    <section className="profile-info">
      <MDBContainer fluid>
        <MDBRow>
          <MDBCol md="8 col-centered">
            <header className="d-flex flex-column justify-content-center align-items-center mb-3">
              <FontAwesomeIcon icon={faUser} size="5x" />
              <p>{profile?.name}</p>
            </header>
            {pages.map(page => {
              const to = page.link ? `/profile${page.link}` : `/profile/${page.title}`;
              console.log(to);
              return (
                <div key={page.title} className="profile-link text-center cap py-3">
                  <Link to={to}>{page.title}</Link>
                </div>
              );
            })}
            {/* <button className="btn btn-primary w-100" onClick={changeLink} >Edit Profile</button> */}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
const Temp = () => <h1 className="f1">This page is under construction...</h1>;

//Kanske flytta data till annan fil
const pages = [
  {
    title: "Updatera profil",
    link: "/updatera",
    component: Edit,
  },
  {
    title: "Favoriter",
    component: Temp,
  },
  {
    title: "Bjud in vänner",
    link: "/bjud-in",
    component: Temp,
  },
  {
    title: "Inställningar",
    component: TeacherSettings,
  },
  {
    title: "hjälp",
    component: Temp,
  },
  {
    title: "Användarvillkor",
    link: "/terms",
    component: Temp,
  },
];
function Main() {
  return (
    <Routes>
      {pages.map(page => (
        <Route path={page.link || `/${page.title}`} element={<page.component />} key={page.title} />
      ))}
      <Route path="/" element={<ProfileInfo />} />
    </Routes>
  );
}

export default Main;
