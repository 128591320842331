import { Box, Button, Container, Grid, Link, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

import { customPost } from "services/api/promises";
import globals from "globalData";
import styles from "./pending.module.css";

{
  /*function PendingCard({
  accept = () => {},
  reject = () => {},
  application,
  lesson,
  index,
  progress,
}) {
  return (
    <div className={styles.cardContainer}>
      <img src="/default-profile.png" alt="August Johannsson" className={styles.profileImage} />
      <div className={styles.cardDetails}>
        <div className={styles.name}>August Johansson</div>
        <div className={styles.rating}>★★★☆☆</div>
      </div>
      <button className={styles.confirmButton}>Confirm</button>
    </div>
  );
}
export default PendingCard; */
}

const accept = async applicationID => {
  const res = await customPost(
    `/api/lesson/student/respond/${applicationID}`,
    { accept: true },
    {},
  );
  const parsed = await res.json();
  const { PaymentRequestToken: token } = parsed.payment_data;
  if (token !== "TestToken")
    window.location = `swish://paymentrequest?token=${token}&callbackurl=${globals.appUrl}/home`;
  window.location.reload();
};
const reject = async applicationID =>
  customPost(`/api/lesson/student/respond/${applicationID}`, { accept: false }, {});

function PendingCard({ application, lesson }) {
  const [isExpanded, setIsExpanded] = useState(false); // State to handle expand/collapse

  const toggleExpand = () => setIsExpanded(!isExpanded); // Function to toggle expand

  return (
    <Container className={styles.cardContainer} onClick={toggleExpand}>
      <img src="/default-profile.png" alt="August Johansson" className={styles.profileImage} />
      <div className={styles.cardDetails}>
        <div className={styles.name}>{application?.teacher?.name || "Anonym Lärare"}</div>
        <div className={styles.rating}>★★★☆☆</div>
        {isExpanded && (
          <div className={styles.expandedDetails}>
            <div className={styles.subjects}>
              <b>Ämnen:</b>
              <p>
                {lesson?.subjects?.join(", ") /*application?.teacher?.subjects?.join(', ')*/ ||
                  "Ämnen saknas."}
              </p>
            </div>
            <div className={styles.location}>
              <b>Plats: </b>
              <p>{lesson?.location}</p>
            </div>
            <div className={styles.time}>
              <b>Tid:</b>
              <p>
                {application?.arrival
                  ? new Date(application.arrival).toLocaleTimeString("sv-SE", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  : "Starttid saknas"}
                {" - "}
                {application?.arrival && lesson.length
                  ? new Date(
                      new Date(application.arrival).getTime() + lesson.length * 60000,
                    ).toLocaleTimeString("sv-SE", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  : "Sluttid saknas"}
                {", "}
                {application?.arrival
                  ? new Date(application.arrival).toLocaleDateString("sv-SE", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : ""}
              </p>
            </div>
            <div className={styles.price}>
              <b>Pris:</b>
              <p>{lesson?.length * globals.price || "Pris saknas."}kr</p>
            </div>
            <div>
              <button onClick={() => reject(application.id)} className={styles.declineButton}>
                Tacka Nej
              </button>
              <button onClick={() => accept(application.id)} className={styles.confirmButton}>
                Acceptera
              </button>
            </div>
          </div>
        )}
      </div>
      {!isExpanded && (
        <button
          onClick={e => {
            e.stopPropagation();
            accept(application.id);
          }}
          className={styles.confirmButton}
        >
          Acceptera
        </button>
      )}
    </Container>
  );
}

export default PendingCard;
