import { Box, Button, Container, Grid, Link, TextField, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";

import { LoginContext } from "app";
import { dateStrToShortSwe, formatTimeDelta, timeStrToSwe } from "services/formats/dates";
import globals from "globalData";
import styles from "./coming.module.css";

function CoomingLesson({ application, lesson, onClick }) {
  //API skickar inte namn på lärare just nu, behöver fixas!
  const { role } = useContext(LoginContext);
  // const name = role === "student" ? lesson.teacher.name : lesson.student.name;

  const name = role === "student" ? lesson?.teacher?.name : lesson?.student?.name;

  // Provide a fallback for name if it's undefined
  {
    /*if (!name) {
    return <div>Loading or missing name...</div>; // Or any other fallback UI
  } */
  }
  return (
    <Container className={styles.cardContainer}>
      <div className={styles.profileSection}>
        <img src="/default-profile.png" alt="August Johansson" className={styles.profileImage} />
        <div className={styles.infoContainer}>
          <div className={styles.name}>{application?.teacher?.name || "Anonym Lärare"}</div>
          <div className={styles.rating}>★★★☆☆</div>
        </div>
        <div className={styles.cardDetails}>
          <div className={styles.location}>
            <p>{lesson.position.adress}</p>
          </div>
          <div className={styles.time}>
            <p>
              {lesson.start_time
                ? new Date(lesson.start_time).toLocaleTimeString("sv-SE", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : "Starttid saknas"}
              {" - "}
              {lesson.start_time && lesson.length
                ? new Date(
                    new Date(lesson.start_time).getTime() + lesson.length * 60000,
                  ).toLocaleTimeString("sv-SE", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : "Sluttid saknas"}
              {", "}
              {lesson.start_time
                ? new Date(lesson.start_time).toLocaleDateString("sv-SE", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : ""}
            </p>
          </div>
          <div className={styles.time_box}>
            Om {formatTimeDelta(new Date(), lesson.start_time)}{" "}
          </div>
        </div>
      </div>
    </Container>

    // <Container className={styles.cardContainer}>
    //   <div className={styles.profileSection}>
    //     <img src="/default-profile.png" alt="August Johansson" className={styles.profileImage} />

    //     <div className={styles.infoContainer}>
    //       <div className={styles.name}>{application?.teacher?.name || "Anonym Lärare"}</div>
    //       <div className={styles.rating}>★★★☆☆</div>
    //     </div>
    //     {/* <div className={styles.subjects}>
    //       <b>Ämnen:</b>
    //       <p>
    //         {lesson?.subjects?.join(", ") ||
    //           "Ämnen saknas."}
    //       </p>
    //     </div> */}
    //     <div className={styles.cardDetails}>
    //       <div className={styles.location}>
    //         <p>{lesson.position.adress}</p>
    //       </div>
    //       <div className={styles.time}>
    //         <p>
    //           {lesson.start_time
    //             ? new Date(lesson.start_time).toLocaleTimeString("sv-SE", {
    //                 hour: "2-digit",
    //                 minute: "2-digit",
    //               })
    //             : "Starttid saknas"}
    //           {" - "}
    //           {lesson.start_time && lesson.length
    //             ? new Date(
    //                 new Date(lesson.start_time).getTime() + lesson.length * 60000,
    //               ).toLocaleTimeString("sv-SE", {
    //                 hour: "2-digit",
    //                 minute: "2-digit",
    //               })
    //             : "Sluttid saknas"}
    //           {", "}
    //           {lesson.start_time
    //             ? new Date(lesson.start_time).toLocaleDateString("sv-SE", {
    //                 year: "numeric",
    //                 month: "long",
    //                 day: "numeric",
    //               })
    //             : ""}
    //         </p>
    //       </div>
    //       <span className={styles.time_box}>
    //         {formatTimeDelta(new Date(), lesson.start_time)}kvar{" "}
    //       </span>
    //     </div>
    //   </div>
    // </Container>

    // <article className={`${styles.info} p-3 mb-5`} onClick={onClick}>
    //   <MDBContainer fluid>
    //     <MDBRow className="d-flex justify-content-center">
    //       <MDBCol size="6" className="d-flex flex-column justify-content-center">
    //         <p className={"f2 cap"}>{name}</p>
    //         <p className={"f3"}>Kl. {timeStrToSwe(lesson.start_time)}</p>
    //         <p className={"f3"}>{dateStrToShortSwe(lesson.start_time)}</p>
    //       </MDBCol>
    //       <MDBCol size="6" className="d-flex flex-column justify-content-center">
    //         {/* <p className="f3">{lesson.length} min</p> */}
    //         <span className={`${styles.time_box} f3 p-1 text-center`}>
    //           {formatTimeDelta(new Date(), lesson.start_time)}kvar
    //         </span>
    //         <a
    //           href={`http://www.google.com/maps/place/${lesson.position.lat},${lesson.position.lng}`}
    //           target="_blank"
    //           className={"text-center mt-1 f3"}
    //           rel="noreferrer"
    //         >
    //           <FontAwesomeIcon className={styles.map_marker} icon={faMapMarkedAlt} />{" "}
    //           <span className={`${styles.pos_link}`}>{lesson.position.adress}</span>
    //         </a>
    //       </MDBCol>
    //     </MDBRow>
    //   </MDBContainer>
    // </article>
  );
}
export default CoomingLesson;
