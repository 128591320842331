import React from "react";

import Schedule from "components/LessonDisplay/schedule/teacherSchedule";

function Home() {
  return (
    <section className="home-section">
      <h1 className="f2 text-center">Detta är home page för lärare</h1>
      <Schedule />
    </section>
  );
}

export default Home;
