import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { Field, Form } from "react-final-form";
import { NotificationContext } from "index";
import { SignupData, setNewPassword } from "api/user/user";
import { useNavigate, useParams } from "react-router-dom";
import { validate, validateRules } from "utils/formValidation";
import LoginLayout from "layouts/LoginLayout";
import React from "react";
import styles from "./reset-password.module.css";
import withMuiTheme from "hocs/WithMuiTheme";

type RegistrationProps = {};

type CustomTextFieldProps = {
  name: string;
  label: string;
  type: string;
  required?: boolean;
};

const validatePasswordsMatch = values => {
  const errors = {};
  if (values.new_password !== values.password_repeated) {
    errors["password_repeated"] = "Lösenorden matchar inte";
  }
  return errors;
};

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  name,
  label,
  type,
  required = false,
}) => (
  <Box marginBottom={2}>
    <Field name={name}>
      {({ input, meta }) => (
        <TextField
          {...input}
          error={meta.touched && meta.error}
          helperText={meta.touched && meta.error}
          label={label}
          type={type}
          fullWidth
          variant="standard"
          required={required}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const allowedPattern = validateRules[name]?.allowed;
            if (allowedPattern !== undefined && !allowedPattern.test(event.target.value)) {
              event.preventDefault();
            } else {
              input.onChange(event);
            }
          }}
        />
      )}
    </Field>
  </Box>
);

type ResetPasswordParams = {
  code: string;
};

const ResetPassword: React.FC<RegistrationProps> = () => {
  const { setNotification } = React.useContext(NotificationContext);
  const [loading, setLoading] = React.useState(false);
  const { code } = useParams<ResetPasswordParams>();
  const navigate = useNavigate();

  const handleSubmit = async (values: SignupData) => {
    setLoading(true);
    const res = await setNewPassword(code, values);
    setLoading(false);
    if (!res.success) {
      setNotification({ show: true, message: res.message, type: "error" });
    } else {
      setNotification({ show: true, message: res.message, type: "success" });
      navigate("/");
    }
  };

  return (
    <LoginLayout>
      <div className={styles.root}>
        <Container>
          <div className={styles.inputContainer}>
            <Box marginBottom={2} alignSelf={"left"}>
              <Typography variant="h4" color="gray">
                Återställ lösenord
              </Typography>
            </Box>
            <Form
              onSubmit={handleSubmit}
              validate={values => {
                const passwordErrors = validatePasswordsMatch(values);
                const otherErrors = validate({
                  new_password: validateRules.password,
                  password_repeated: validateRules.password,
                })(values);
                return { ...otherErrors, ...passwordErrors };
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div>
                      <CustomTextField
                        name="new_password"
                        label="Lösenord"
                        type="password"
                        required
                      />
                      <CustomTextField
                        name="password_repeated"
                        label="Repetera lösenord"
                        type="password"
                        required
                      />
                    </div>
                    <Box marginTop={5} display="flex" justifyContent="flex-end" columnGap={2}>
                      <Button color="primary" href="/">
                        Tillbaka
                      </Button>
                      <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        Ändra lösenord
                      </Button>
                    </Box>
                  </div>
                </form>
              )}
            />
          </div>
        </Container>
      </div>
    </LoginLayout>
  );
};

export default ResetPassword;
