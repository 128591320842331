import React from "react";

import { useLogin } from "hooks/fetch-hooks";

import AdminApp from "admin-pages/app";
import Auth from "components/auth";
import Loading from "components/Loading";
import StudentApp from "student-pages/app";
import TeacherApp from "teacher-pages/app";
import withMuiTheme from "hocs/WithMuiTheme";

export const LoginContext = React.createContext(null);

const App = () => {
  const { signedIn, setSignedIn, role, profile, setRole, setProfile, refreshData } = useLogin();
  const [studentView, setStudentView] = React.useState(false); //Setting to allow teachers to be in student view

  if (signedIn === false) return <Auth setSignedIn={setSignedIn} refreshData={refreshData} />;

  if (signedIn && (role === "student" || studentView)) {
    return (
      <LoginContext.Provider value={{ signedIn, setSignedIn, role, profile, setRole, setProfile }}>
        <StudentApp />
      </LoginContext.Provider>
    );
  }
  if (signedIn && role === "teacher") {
    return (
      <LoginContext.Provider
        value={{
          signedIn,
          setSignedIn,
          role,
          profile,
          setProfile,
          setStudentView,
        }}
      >
        <TeacherApp />
      </LoginContext.Provider>
    );
  }
  if (signedIn && role === "admin") {
    return (
      <LoginContext.Provider
        value={{
          signedIn,
          setSignedIn,
          role,
          profile,
          setProfile,
          setStudentView,
        }}
      >
        <AdminApp />
      </LoginContext.Provider>
    );
  }
  return (
    <div className="fullscreen-container">
      <Loading />
    </div>
  );
};

export default App;
