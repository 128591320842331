import { Box, Button, Container, Link, TextField, Typography } from "@mui/material";
import { Field, Form } from "react-final-form";
import { LoginData, login } from "api/user/user";
import { NotificationContext } from "index";
import { requestNotificationPermissions } from "services/notifications";
import { useNavigate } from "react-router-dom";
import { validate, validateRules } from "../../../utils/formValidation";
import LoginLayout from "layouts/LoginLayout";
import React from "react";
import styles from "./Login.module.css";

type LoginProps = {
  refreshData: () => void;
  setSignedIn: (signedIn: boolean) => void;
  setPage: (page: number) => void;
};

const Login: React.FC<LoginProps> = ({ refreshData, setSignedIn, setPage }) => {
  const [loading, setLoading] = React.useState(false);
  const { setNotification } = React.useContext(NotificationContext);
  const navigate = useNavigate();

  const handleSubmit = async (values: LoginData) => {
    requestNotificationPermissions();
    setLoading(true);
    const res = await login(values);
    setLoading(false);

    if (!res.success) {
      setNotification({ show: true, message: res.message, type: "error" });
    } else {
      let counter = 0;
      const refresh = () => {
        try {
          refreshData();
        } catch (err) {
          if (counter < 10) {
            counter += 1;
            setTimeout(refresh, 100);
          }
        }
      };
      localStorage.setItem("access_token", res.data["access_token"]);
      setSignedIn(true);
      navigate("/");
      refresh();
    }
  };

  return (
    <LoginLayout>
      <div className={styles.body}>
        <Typography className={styles.title}>GradeLift</Typography>
        <Container>
          <Form
            onSubmit={handleSubmit}
            validate={validate({
              email: validateRules.email,
              password: validateRules.password,
            })}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className={styles.inputContainer}>
                  <Box marginBottom={2}>
                    <Field name="email">
                      {({ input }) => (
                        <TextField
                          {...input}
                          label="Email"
                          type="email"
                          fullWidth
                          variant="standard"
                        />
                      )}
                    </Field>
                  </Box>
                  <Box marginBottom={5}>
                    <Field name="password">
                      {({ input }) => (
                        <TextField
                          {...input}
                          label="Lösenord"
                          type="password"
                          fullWidth
                          variant="standard"
                        />
                      )}
                    </Field>
                  </Box>
                </div>
                <Box marginBottom={3} display="flex" justifyContent="center" columnGap={2}>
                  <Button type="submit" variant="contained" color="primary" disabled={loading}>
                    Logga in
                  </Button>
                  <Button
                    type="submit"
                    color="inherit"
                    onClick={() => {
                      setPage(1);
                    }}
                  >
                    Skapa konto
                  </Button>
                </Box>
              </form>
            )}
          />
        </Container>
        <Typography component="span" variant="body2">
          Problem med att logga in?{" "}
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setPage(2);
            }}
          >
            Återställ lösenord
          </Link>
        </Typography>
      </div>
    </LoginLayout>
  );
};

export default Login;
