import React, { useContext, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HelpIcon from "@mui/icons-material/Help";
import cx from "classnames";

import { HomeTitle } from "student-pages/home";
import { LocationSearchHelper } from "student-pages/add-booking/ChooseLocation";
import { SearchBar } from "student-pages/add-booking/ChooseSubjects";
import { TimeDateTitle } from "student-pages/add-booking/ChooseTimeDate";
import { TopBarContext } from "student-pages/app";
import { useNavigate } from "react-router-dom";
import Logo from "../../Logo";
import styles from "./styles.module.css";

// Map of components that can be displayed in the TopBar component
const ContentMap = {
  SearchBar,
  HomeTitle,
  LocationSearchHelper,
  TimeDateTitle,
};

// The TopBar component is a functional component that displays a navbar at the top of the screen
function TopBar() {
  const { topContent, step, setStep, totalSteps, helperText, helperTitle } =
    useContext(TopBarContext);
  const { childId, childProps, childPos, flatTop } = topContent || {};
  const Child = ContentMap[childId];
  const [displayHelpText, setDisplayHelpText] = useState(false);

  const hideBackButton = step === 0 || step === totalSteps;
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.parent}>
        <nav className={`navbar sticky-top ${styles.navbar}`}>
          <div
            className="container-fluid w-100 justify-content-between align-items-center"
            style={{ margin: "8px" }}
          >
            <Logo color="green" onClick={() => navigate("/")} />
            {helperText && (
              <HelpIcon
                className={cx("mb-1", styles.help_button)}
                onClick={() => setDisplayHelpText(true)}
              />
            )}
          </div>
          {childId in ContentMap && childPos !== "bottom" && <Child {...childProps} />}
          {/* Smooth edge */}
          {flatTop !== true && (
            <div className={`${styles.decoration_edge}`}>
              <div className={`${styles.background_board}`} />
              <div />
            </div>
          )}
        </nav>
      </div>
      <div className={cx(styles.bottom_box)}>
        <FontAwesomeIcon
          className={cx(styles.back_button, hideBackButton && styles.disabled)}
          onClick={() => {
            setStep(step - 1);
          }}
          icon={faLongArrowAltLeft}
        />
        {childId in ContentMap && childPos === "bottom" && <Child {...childProps} />}
      </div>
      <Dialog
        open={displayHelpText}
        onClose={() => setDisplayHelpText(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{helperTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{helperText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDisplayHelpText(false)} autoFocus>
            Stäng
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TopBar;
