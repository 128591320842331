import { Box, Container } from "@mui/material";
import React from "react";

type PageProps = {
  children: React.ReactNode;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  sidePadding?: number;
};

const Page: React.FC<PageProps> = ({ children, size = "md", sidePadding = 2 }) => (
  <Container maxWidth={size}>
    <Box sx={{ py: 4, px: sidePadding }}>{children}</Box>
  </Container>
);

export default Page;
