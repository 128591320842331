import React from "react";
import globals from "globalData";
import styles from "./awaiting.module.css";

function AwaitingCard({ application, lesson }) {
  return (
    <div className={styles.cardContainer}>
      <img src="/default-profile.png" alt="August Johansson" className={styles.profileImage} />
      <div className={styles.cardDetails}>
        <div className={styles.name}>{application?.teacher?.name || "Anonym Lärare"}</div>
        <div className={styles.rating}>★★★☆☆</div>
        <div className={styles.subjects}>
          <b>Ämnen:</b>
          <p>
            {lesson?.subjects?.join(", ") /*application?.teacher?.subjects?.join(', ')*/ ||
              "Ämnen saknas."}
          </p>
        </div>
        <div className={styles.location}>
          <b>Plats: </b>
          <p>{lesson?.location}</p>
        </div>
        <div className={styles.time}>
          <b>Tid:</b>
          <p>
            {application?.arrival
              ? new Date(application.arrival).toLocaleTimeString("sv-SE", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "Starttid saknas"}
            {" - "}
            {application?.arrival && lesson.length
              ? new Date(
                  new Date(application.arrival).getTime() + lesson.length * 60000,
                ).toLocaleTimeString("sv-SE", {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              : "Sluttid saknas"}
            {", "}
            {application?.arrival
              ? new Date(application.arrival).toLocaleDateString("sv-SE", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : ""}
          </p>
        </div>
        <div className={styles.price}>
          <b>Pris:</b>
          <p>{lesson?.length * globals.price || "Pris saknas."}kr</p>
        </div>
      </div>
    </div>
  );
}
export default AwaitingCard;
