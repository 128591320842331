type ValidationRule = {
  rule: RegExp;
  message: string;
  allowed?: RegExp;
};

type ValidationRules = {
  [key: string]: ValidationRule;
};

export const validateRules: ValidationRules = {
  name: {
    rule: /^(?=.*\s)\D{1,100}\S$/,
    message: "Please enter a valid name.",
    allowed: /^.*$/, // All characters allowed
  },
  email: {
    rule: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    message: "Please enter a valid email.",
    allowed: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~@-]*$/, // Typical email characters allowed
  },
  password: {
    rule: /.*/,
    // rule: "^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,64}$",
    message:
      "Password must contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character, and be between 8-64 characters.",
    allowed: /^.*$/, // All characters allowed
  },
  phonenumber: {
    rule: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
    message: "Please enter a valid phone number.",
    allowed: /^[0-9+\-().\s]*$/, // Numbers, +, -, (, ), ., and space allowed
  },
  birthyear: {
    rule: /^(19[0-9]{2}|20[0-1][0-9])$/,
    message: "Please enter a valid birth year between 1900 and 2019.",
    allowed: /^[0-9]*$/, // Only numbers allowed
  },
};

interface OptionalFields {
  [key: string]: boolean;
}

export const validate =
  (rules: ValidationRules, optionalFields?: OptionalFields) => (values: { [key: string]: any }) => {
    const errors: { [key: string]: string } = {};

    Object.keys(rules).forEach(field => {
      const isOptionalAndNotProvided =
        optionalFields?.[field] && (values[field] == null || values[field] === "");

      if (!isOptionalAndNotProvided) {
        if (values[field] == null || !rules[field].rule.test(values[field])) {
          errors[field] = rules[field].message;
        }
      }
    });

    return errors;
  };
