import { Box, Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "components/Logo";
import React from "react";
import styles from "./LoginLayout.module.css";

type LoginProps = {
  children: React.ReactNode;
};

const LoginLayout: React.FC<LoginProps> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <main className={styles.root}>
      <Box component="nav" columnGap={0} className={styles.nav}>
        <Logo onClick={() => navigate("/")} />
        <Box display="flex">
          <Button color="inherit" href="/contact">
            Support
          </Button>
          <Button color="inherit" href="/about-us">
            Om oss
          </Button>
        </Box>
      </Box>
      <Container className={styles.body}>{children}</Container>
    </main>
  );
};

export default LoginLayout;
