import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faHome, faUniversity, faUser } from "@fortawesome/free-solid-svg-icons";

import "./teacher-nav.css";

export const teacherLinks = {
  HOME: "/home",
  FLOW: "/flow",
  HISTORY: "/history",
  PROFILE: "/profile",
};

const tabs = [
  {
    route: teacherLinks.HOME,
    icon: faHome,
    label: "Home",
  },
  {
    route: teacherLinks.FLOW,
    icon: faBookmark,
    label: "Book Flow",
  },
  {
    route: teacherLinks.HISTORY,
    icon: faUniversity,
    label: "History",
  },
  {
    route: teacherLinks.PROFILE,
    icon: faUser,
    label: "Profile",
  },
];

const Navigation = props => (
  <div className="teacher-nav-parent">
    <nav className="navbar fixed-bottom navbar-light bottom-tab-nav" role="navigation">
      <Nav className="w-100">
        <div className="d-flex flex-row justify-content-around w-100">
          {tabs.map((tab, index) => (
            <NavItem key={`tab-${index}`}>
              <NavLink to={tab.route} className="nav-link bottom-nav-link" activeClassName="active">
                <div className="row d-flex flex-column justify-content-center align-items-center">
                  <div className="d-flex justify-content-center">
                    <FontAwesomeIcon size="lg" icon={tab.icon} />
                  </div>
                </div>
              </NavLink>
            </NavItem>
          ))}
        </div>
      </Nav>
    </nav>
  </div>
);

export default Navigation;
