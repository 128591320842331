import { Box, Link, Typography } from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useLogin } from "hooks/fetch-hooks";
import Loading from "components/Loading";
import Page from "components/Page";
import PageTitle from "components/navigation/PageTitle";
import React from "react";
import Support from "./support";
import TermsAndConditions from "./terms";
import UpdateProfile from "./update-profile";
import withLoadingScreen from "hocs/WithLoadingScreen";
import withSignedIn from "hocs/WithSignedIn";

const Logout: React.FC = () => {
  const { logout } = useLogin();

  React.useEffect(() => {
    logout();
  }, [logout]);

  return <Loading />;
};

const ProfileInfo: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <PageTitle>Inställningar</PageTitle>
      {pages.map(({ title, link }) => (
        <Box key={title} sx={{ pb: 4 }}>
          <Typography variant="body1" align="center">
            <Link onClick={() => navigate(link)} variant="passive">
              {title}
            </Link>
          </Typography>
        </Box>
      ))}
    </Page>
  );
};

type PageType = {
  title: string;
  link: string;
  component: React.ComponentType;
};

const pages: PageType[] = [
  {
    title: "Uppdatera profil",
    link: "update-profile",
    component: UpdateProfile,
  },
  // {
  //   title: "Favoriter",
  //   link: "favorites",
  //   component: Temp,
  // },
  // {
  //   title: "Bjud in vänner",
  //   link: "send-invite",
  //   component: Temp,
  // },
  // {
  //   title: "Inställningar",
  //   link: "settings",
  //   component: Temp,
  // },
  {
    title: "Allmänna villkor",
    link: "terms-and-conditions",
    component: TermsAndConditions,
  },
  {
    title: "Support",
    link: "support",
    component: Support,
  },
  {
    title: "Logga ut",
    link: "logout",
    component: Logout,
  },
];

const Profile: React.FC = () => (
  <Routes>
    {pages.map(({ link, component: Component, title }) => (
      <Route path={link} element={<Component />} key={title} />
    ))}
    <Route path="/" element={<ProfileInfo />} />
  </Routes>
);
export default withLoadingScreen(withSignedIn(Profile));
