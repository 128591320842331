export const sendRequest = async <T,>(url: string, userData: T, token?: string) => {
  const headers: { [key: string]: string } = {
    "content-type": "application/json",
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    if (response.status === 500) {
      return {
        success: false,
        message: "Kunde inte ansluta till servern, försök igen senare.",
        data: null,
      };
    }
    try {
      const json = await response.json();
      return { success: false, message: json.detail.msg, data: null };
    } catch (err) {
      return { success: false, message: err.message, data: null };
    }
  } else {
    const json = await response.json();
    return { success: true, message: json.detail?.msg, data: json };
  }
};

export type SignupData = {
  name: string;
  phonenumber: string;
  email: string;
  password: string;
  default_address?: string;
  birthday?: string;
};

export const signup = async (userData: SignupData) => await sendRequest("/api/signup", userData);

export type LoginData = {
  email: string;
  password: string;
};

export const login = async (userData: LoginData) => await sendRequest("/api/login", userData);

export type UpdateProfileData = {
  name: string;
  phonenumber: string;
  email: string;
  default_address?: string;
  birthday?: string;
};

export const updateProfile = async (userData: UpdateProfileData, token?: string) =>
  await sendRequest("/api/profile/update", userData, token);

export const resetPassword = async (email: string) =>
  await sendRequest("/api/profile/verify/password/req-new", email);

export const setNewPassword = async (code: string, newPassword) =>
  await sendRequest(`/api/profile/verify/password/reset/${code}`, newPassword);
