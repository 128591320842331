import React from "react";

import AwaitingLesson from "components/LessonDisplay/AwaitingLesson";

function LessonList({ data: awaitinglessons, isLoading }) {
  return (
    <div className={`px-3`}>
      {!isLoading &&
        awaitinglessons?.map(lesson => <AwaitingLesson key={lesson.id} lesson={lesson} />)}
    </div>
  );
}

export default LessonList;
