import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { TopBarContext } from "student-pages/app";
import { faBook, faClockRotateLeft, faHome, faUser } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import cx from "classnames";

import styles from "./student-navigation.module.css";

// Define constants for the routes of the app
export const studentLinks = {
  HOME: "/",
  BOOK: "/book",
  HISTORY: "/history",
  PROFILE: "/profile",
};

// Define the tabs that will be displayed in the Navigation component
const tabs = [
  {
    route: studentLinks.HOME,
    icon: faHome,
    label: "Home",
  },
  {
    route: studentLinks.BOOK,
    icon: faBook,
    label: "Book Time",
  },
  {
    route: studentLinks.HISTORY,
    icon: faClockRotateLeft,
    label: "History",
  },
  {
    route: studentLinks.PROFILE,
    icon: faUser,
    label: "Profile",
  },
];

// The Navigation component is a functional component that displays a navbar with a series of tabs at the bottom of the screen
const Navigation = () => {
  const { setStep, setTotalSteps } = React.useContext(TopBarContext);

  const resetPage = () => {
    // Resets all page specific values to their initial values
    setStep(0);
    setTotalSteps(0);
  };

  return (
    <div className={styles.root}>
      <div className={styles.navbar}>
        {tabs.map((tab, index) => (
          <div className={styles.navItem} key={index}>
            <NavLink
              to={tab.route}
              onClick={resetPage}
              className={({ isActive }) => cx(styles.navLink, isActive && styles.activeNavLink)}
            >
              <FontAwesomeIcon size="lg" icon={tab.icon} />
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Navigation;
