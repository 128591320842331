import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import cx from "classnames";
import styles from "./NotificationModal.module.css";

type NotificationModalProps = {
  message: string;
  show: boolean;
  onClose: () => void;
  type?: "success" | "error" | "warning" | "info";
  timeout?: number;
};

const NotificationModal: React.FC<NotificationModalProps> = ({
  message,
  show,
  onClose,
  type = "success",
  timeout = 5000,
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!show) {
      setProgress(0);
      return;
    }

    const timer = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress >= 100) {
          clearInterval(timer);
          onClose();
        }
        return oldProgress + 10 / (timeout / 100);
      });
    }, 10);

    return () => {
      clearInterval(timer);
    };
  }, [onClose, timeout, show]);

  if (!show) return null;

  return (
    <div className={styles.root}>
      <div className={cx(styles.container, styles[type])}>
        <div className={styles.topBar}>
          <div className={styles.progressBarContainer}>
            <div className={styles.progressBar} style={{ width: `${progress}%` }} />
          </div>
          <CloseIcon onClick={onClose} className={styles.closeButton} />
        </div>
        <Typography color="white">{message}</Typography>
      </div>
    </div>
  );
};

export default NotificationModal;
