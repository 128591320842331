import React from "react";

import CoomingLesson from "components/LessonDisplay/ComingLesson";

function LessonList({ data: coomingLessons, isLoading }) {
  return (
    <div className={`px-3`}>
      {!isLoading &&
        coomingLessons?.map(lesson => <CoomingLesson key={lesson.id} lesson={lesson} />)}
    </div>
  );
}

export default LessonList;
