import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { customPost } from "services/api/promises";
import { useFetch } from "hooks/fetch-hooks";
import { useState } from "react";
import Loading from "components/Loading";
import React from "react";

function Authorize() {
  const { data, isLoading } = useFetch("/api/lesson/student/subjects-list", {});
  const [teacherData, setTeacherData] = useState(null);
  const [added, setAdded] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const updateData = async email => {
    const res = await customPost("/api/admin/get-user/email", { email }, { authorize: true });
    if (res.status !== 200) {
      setTeacherData(null);
      setIsValidEmail(false);
      return;
    }
    const parsed = await res.json();
    setTeacherData(parsed);
    setIsValidEmail(true);
  };

  const toggleSubj = subj => {
    if (added.includes(subj)) {
      setAdded(added.filter(el => el !== subj));
    } else {
      setAdded([...added, subj]);
    }
  };

  const handleChange = event => {
    if (event.target.name !== "email") return;
    updateData(event.target.value);
  };

  const handleSubmit = () => {
    if (teacherData && "id" in teacherData) {
      if (!window.confirm(`vill du göra ${teacherData.name} till teacher.`)) return;
      customPost(
        `/api/admin/validate-teacher/${teacherData.id}`,
        { authorized_teaching_subjects: added },
        {},
      ).then(response => {
        response.text().then(text => {
          alert(`status ${response.status} ${text}`);
        });
      });
    } else {
      alert("Ingen användare med den mailen.");
    }
  };

  if (isLoading) return <Loading />;

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Auktorisera en lärare via formuläret
      </Typography>
      <Typography variant="body1" gutterBottom>
        Fyll i lärarens email och välj vilka ämnen läraren ska kunna undervisa i.
      </Typography>
      <TextField
        type="email"
        placeholder="Email"
        name="email"
        fullWidth
        variant="outlined"
        margin="normal"
        onChange={handleChange}
        error={!isValidEmail}
      />
      <Container
        sx={{
          overflow: "auto",
          maxHeight: "55vh",
          bgcolor: "#f1f1f1",
          padding: 2,
          borderRadius: 3,
          my: 2,
        }}
      >
        {Object.keys(data?.subjects || {}).map(category => (
          <React.Fragment key={category}>
            {data.subjects[category].map(subj => {
              if (typeof subj !== "string") return null;
              return (
                <FormControlLabel
                  key={subj}
                  control={
                    <Checkbox checked={added.includes(subj)} onChange={() => toggleSubj(subj)} />
                  }
                  label={subj}
                />
              );
            })}
          </React.Fragment>
        ))}
      </Container>
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Auktorisera
      </Button>
    </Box>
  );
}

export default Authorize;
