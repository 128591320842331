import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import App from "./app";
import React, { createContext } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

import "./index.css";
import AboutUs from "pages/about-us";
import Contact from "pages/contact";
import NotificationModal from "components/NotificationModal";
import ResetPassword from "pages/reset-password";
import withMuiTheme from "hocs/WithMuiTheme";

export const NotificationContext = createContext(null);

const Routing = () => {
  const [notification, setNotification] = React.useState(null);

  return (
    <NotificationContext.Provider value={{ notification, setNotification }}>
      <NotificationModal
        show={notification?.show}
        message={notification?.message}
        type={notification?.type}
        onClose={() => setNotification({ show: false, message: "", type: "success" })}
      />
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/reset-password/:code" element={<ResetPassword />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/*" element={<App />} />
        </Routes>
      </Router>
    </NotificationContext.Provider>
  );
};

const Main = withMuiTheme(Routing);

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById("root"),
);

serviceWorkerRegistration.register();
//subscribeUser();
//renew_sub();
reportWebVitals();
