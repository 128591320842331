import React from "react";

import { useFetch } from "hooks/fetch-hooks";

import Schedule from "./scheduled";

import styles from "./schedule.module.css";

function TeacherSchedule() {
  const data = useFetch("/api/lesson/teacher/scheduled", { authorize: true });
  return (
    <section className={`${styles.schedule}`}>
      <h1 className="f2 text-center my-2">Kommande Lektioner</h1>
      <Schedule {...data} />
    </section>
  );
}

export default TeacherSchedule;
