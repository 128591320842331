import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useLogin = () => {
  const navigate = useNavigate();
  const [signedIn, setSignedIn] = useState(null);
  const [role, setRole] = useState(null);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await fetch("/api/profile/", {
        method: "GET",
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
      });
      if (res.ok) {
        setSignedIn(true);
        res
          .json()
          .then(r => {
            setRole(r.role);
            setProfile(r);
          })
          .catch(err => alert(err));
      } else {
        setSignedIn(false);
        setRole(null);
      }
    })();
  }, []);

  const refreshData = async () => {
    const res = await fetch("/api/profile/", {
      method: "GET",
      headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
    });
    if (res.ok) {
      res.json().then(r => {
        setRole(r.role);
        setProfile(r);
      });
    }
  };

  /**
   * Logs out the user.
   *
   * This function performs the following actions:
   * 1. Removes the user's access token from local storage.
   * 2. Updates the `signedIn` state to `false`, indicating the user is no longer authenticated.
   * 3. Resets the user role to `null`.
   * 4. Redirects the user to the login page.
   *
   * @async
   * @function
   * @returns {Promise<void>}
   * @note Ensure that route guards or checks are in place to prevent unauthorized access
   *       to certain routes after logout.
   */
  const logout = async () => {
    localStorage.removeItem("access_token");
    setSignedIn(false);
    setRole(null);
    navigate("/");
    navigate(0);
  };

  return {
    signedIn,
    setSignedIn,
    role,
    profile,
    setRole,
    setProfile,
    refreshData,
    logout,
  };
};

export const useFetch = (url, { fetchData = { method: "GET" }, authorize = false }) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  if (authorize) {
    fetchData["headers"] = { Authorization: "Bearer " + localStorage.getItem("access_token") };
  }
  useEffect(() => {
    (async () => {
      const res = await fetch(url, fetchData);
      res.json().then(r => {
        setData(r);
        setIsLoading(false);
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData, setIsLoading]);
  return { data, isLoading };
};
const fetchHooks = { useLogin, useFetch };
export default fetchHooks;
