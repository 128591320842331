import React, { useState } from "react";

import { useFetch } from "hooks/fetch-hooks";
import FinishedLesson from "components/LessonDisplay/FinishedLesson";
import Loading from "components/Loading";

const History = () => {
  const { data: finishedLessons, isLoading } = useFetch("/api/lesson/student/history", {
    authorize: true,
  });

  if (isLoading) return <Loading />;
  return (
    <section className="history">
      <h1 className="f2 text-center cap">Mina Tidigare Lektioner</h1>
      {finishedLessons?.map(lesson => (
        <FinishedLesson key={lesson.id} lesson={lesson} onClick={() => {}} />
      ))}
    </section>
  );
};
export default History;
