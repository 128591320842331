import { Box, Container, Typography } from "@mui/material";
import Page from "components/Page";
import PageTitle from "components/navigation/PageTitle";
import React from "react";

const TermsAndConditions: React.FC = () => {
  const terms = [
    {
      title: "Introduktion",
      description:
        "Välkommen till GradeLift. Genom att använda vår tjänst accepterar du (användaren) dessa allmänna villkor i sin helhet. Om användaren inte godkänner dessa villkor eller någon del av dessa villkor ska användaren inte heller ta del av plattformen.",
    },
    {
      title: "Förståelse",
      description:
        "Vår tjänst syftar till att koppla samman studenter med lärare för att hjälpa dem med ett eller flera ämnen av deras val. Tjänsten är avgiftsbelagd.",
    },
    {
      title: "Användaransvar",
      description:
        "Användaren förstår och godkänner att GradeLift endast fungerar som en plattform som kopplar samman studenter med lärare. Vi kan inte garantera lärarens kvalifikationer eller kvaliteten på undervisningen.",
    },
    {
      title: "Avgifter och betalningar",
      description:
        "Betalning av tjänsten görs genom vår webbplats. Alla avgifter och priser är angivna på vår webbplats och kan komma att ändras. Återbetalningar hanteras från fall till fall och bestäms av vår återbetalningspolicy.",
    },
    {
      title: "Avbokningspolicy",
      description:
        "Om en student avbokar en lektion inom 24 timmar före lektionsstart, kommer ingen återbetalning att göras.",
    },
    {
      title: "Användarens förpliktelser",
      description:
        "Användare får inte använda vår tjänst på något sätt som orsakar eller kan orsaka skada för tjänsten eller nedsättning av tillgängligheten. Varje användare ansvarar för att säkerställa att den information de tillhandahåller är korrekt och uppdaterad.",
    },
    {
      title: "Begränsningar av ansvar",
      description:
        "GradeLift kommer inte att hållas ansvarigt för eventuell skada, förlust eller skada som kan uppstå som ett resultat av användning av vår tjänst.",
    },
    {
      title: "Begränsningar av ansvar",
      description:
        "GradeLift förbehåller sig rätten att när som helst ändra dessa användarvillkor. När vi gör ändringar kommer vi att informera våra användare genom att uppdatera datumet för senaste ändring högst upp på denna sida eller genom att skicka en notifikation till registrerade användare.",
    },
    {
      title: "Lag och jurisdiktion",
      description:
        "Dessa villkor regleras av och tolkas i enlighet med svensk lagstiftning och du samtycker till att den exklusiva jurisdiktionen ligger hos de svenska domstolarna.",
    },
    {
      title: "Kontaktinformation",
      description:
        "Om du har några frågor om dessa villkor, vänligen kontakta oss via villkor@gradelift.se",
    },
    {
      title: "Integritetspolicy",
      description:
        "All personlig information som du tillhandahåller eller som vi samlar in genom din användning av tjänsten behandlas i enlighet med vår. Vi uppmanar dig att noggrant läsa igenom vår Integritetspolicy för att förstå hur vi hanterar din data.",
    },
    {
      title: "Immateriella rättigheter",
      description:
        "Allt innehåll på GradeLift, inklusive text, grafik, logotyper och kod, ägs av GradeLift och är skyddat av internationella upphovsrättslagar. Användare får inte kopiera, modifiera eller distribuera vårt innehåll utan vårt uttryckliga skriftliga medgivande.",
    },
    {
      title: "Avslutning av tjänsten",
      description:
        "GradeLift förbehåller sig rätten att när som helst avsluta eller begränsa din tillgång till tjänsten, utan varsel, av någon anledning, inklusive, men inte begränsat till, brott mot dessa villkor eller om vi misstänker obehörig användning.",
    },
    {
      title: "Tvistlösning",
      description:
        "Alla tvister, oenigheter eller krav som uppstår ur eller i samband med dessa villkor ska först försöka lösas genom förhandling. Om en tvist inte kan lösas genom förhandling inom trettio (30) dagar, ska tvisten avgöras av en behörig domstol i Sverige.",
    },
    {
      title: "Användaruppförande",
      description:
        "Användare förväntas uppträda respektfullt och professionellt när de interagerar med andra på GradeLift. All form av trakasserier, diskriminering eller olämpligt beteende kommer inte att tolereras och kan leda till omedelbar avstängning från tjänsten.",
    },
  ];

  return (
    <Page>
      <PageTitle>Allmänna villkor</PageTitle>
      <Box sx={{ mt: 4 }}>
        {terms.map((term, index) => (
          <div key={index}>
            <Typography variant="h6" align="left" gutterBottom>
              {index + 1}. {term.title}
            </Typography>
            <Typography variant="body1" align="left" paragraph>
              {term.description}
            </Typography>
          </div>
        ))}
      </Box>
    </Page>
  );
};

export default TermsAndConditions;
