import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00cc7c",
    },
    secondary: {
      main: "#fcfaf9",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: "#fcfaf9",
          "&:hover": {
            color: "#c1c1c1;",
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: "#b8c2b4",
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "passive" },
          style: {
            color: "darkgray !important",
            textDecoration: "none !important",
            "&:hover": {
              color: "gray !important",
            },
          },
        },
      ],
    },
  },
});

const withMuiTheme = Component => {
  function WithTheme(props) {
    return (
      <ThemeProvider theme={theme}>
        <Component {...props} />
      </ThemeProvider>
    );
  }

  return WithTheme;
};

export default withMuiTheme;
