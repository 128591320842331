import { useNavigate } from "react-router-dom";
import BookedInfo from "./BookedInfo";
import React, { useContext } from "react";

import CustomContainer from "components/CustomContainer";

//Local
import { TopBarContext } from "student-pages/app";
import ChooseDate from "./ChooseTimeDate";
import ChooseLocation from "./ChooseLocation";
import ChooseSubjects from "./ChooseSubjects";
import Confirm from "./Confirm";

import "./index.css";
const formSteps = [ChooseSubjects, ChooseDate, ChooseLocation, Confirm];
const containerSkippers = [3]; //indexes in formSteps

const prepData = data => ({
  ...data,
  subjects: [...data.subjects],
});

const handleSubmit = (formData, setFormData) => {
  const sendData = prepData(formData);
  async function fetchData() {
    const res = await fetch("/api/lesson/student/create", {
      body: JSON.stringify(prepData(formData)),
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        "content-type": "application/json",
      },
    });
    if (!res.ok) alert("error with request");
  }
  fetchData();
};

const initialFormData = Object.freeze({
  note: "", //kanske möjligt att ha den nuvarande adressen som startvärde
  date: new Date(),
  length: 60, //min
  earliest_potential_start: new Date("1980-01-01T13:30:00"),
  latest_potential_start: new Date("1980-01-01T14:30:00"),
  subjects: [],
  position: {
    lat: 59.3459867,
    lng: 18.08,
    adress: "Drottnings Sofias väg1x",
    id: null,
  },
});

function AddBooking() {
  // Step state controls form progress and is connected to the back button
  const { step, setStep, setTotalSteps } = useContext(TopBarContext);
  const [formData, setFormData] = React.useState(initialFormData);
  const [allowNxt, setAllowNxt] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    setAllowNxt(true);
  }, [step]);

  React.useEffect(() => {
    // Reset the steps on the first render
    setStep(0);
    setTotalSteps(formSteps.length);
  }, [setStep, setTotalSteps]);

  const goToNext = e => {
    e.preventDefault();
    if (!allowNxt) {
      return;
    }
    setStep(prev => prev + 1);

    if (step + 1 === formSteps.length) {
      handleSubmit(formData);
      setFormData(initialFormData);
    }
  };
  if (step >= formSteps.length) {
    return <BookedInfo />;
  }
  const Comp = formSteps[step];

  if (!setFormData) {
    return <></>;
  }
  return (
    <section className="add-booking-section">
      {/* {step > 0 && <button className="back-btn f1 text-center" onClick={() => { setStep(Math.max(0, step - 1)); }}><FontAwesomeIcon icon={faLongArrowAltLeft}></FontAwesomeIcon></button>} */}
      {containerSkippers.includes(step) ? (
        <Comp
          formData={formData}
          setFormData={setFormData}
          step={step}
          goToNext={goToNext}
          allowNxt={allowNxt}
          setAllowNxt={setAllowNxt}
        />
      ) : (
        <CustomContainer>
          <Comp
            formData={formData}
            setFormData={setFormData}
            step={step}
            goToNext={goToNext}
            allowNxt={allowNxt}
            setAllowNxt={setAllowNxt}
          />
        </CustomContainer>
      )}
    </section>
  );
}

export default AddBooking;

/* Different url for each step
const Step = ()=>{
    const {step: urlStep} = useParams()
    const history = useNavigate()
    const {step, setStep, formData} = useContext(FormContext)
    if(!(Number(urlStep) !== NaN && 0 <= Number(urlStep) && Number(urlStep) < formSteps.length)){
        history.push("/book/0")
        return <h1>Fel</h1>
    }
    setStep(Number(urlStep));
    const goToNext = e =>{
        if(step+1 === formSteps.length){
            handleSubmit(e, formData)
            history.push("/home")
        } else {
            history.push(`/book/${step+1}`)
        }
        setStep(prev=>prev+1)
    }

    const Comp = formSteps[step];
    return <form onSubmit={goToNext}>
        <Comp ></Comp>
            <button type="submit" className="btn btn-warning next-btn my-5" style={{margin: 0}}><p className="h2">{step === formSteps.length-1 ? "Bekräfta" : "Nästa"}</p></button>
    </form>
}*/
