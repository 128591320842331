import React from "react";

import { CircularProgress } from "@mui/material";
import { useFetch } from "hooks/fetch-hooks";
import FinishedLesson from "components/LessonDisplay/FinishedLesson";
import NoLessons from "./NoLessons";
import Page from "components/Page";
import PageTitle from "components/navigation/PageTitle";

const Loading = () => (
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      rowGap: "0.4rem",
    }}
  >
    <CircularProgress />
  </div>
);

const LessonsPage = ({ lessons }) => {
  if (lessons.length === 0) {
    return <NoLessons />;
  }

  return (
    <div>
      {lessons.map(lesson => (
        <FinishedLesson key={lesson.id} lesson={lesson} onClick={() => {}} />
      ))}
    </div>
  );
};

const History = () => {
  const { data: finishedLessons, isLoading } = useFetch("/api/lesson/student/history", {
    authorize: true,
  });

  return (
    <Page sidePadding={0}>
      <PageTitle>Mina Tidigare Lektioner</PageTitle>

      {isLoading ? (
        <Loading />
      ) : (
        <div style={{ animation: "fadeIn 0.5s" }}>
          <LessonsPage lessons={finishedLessons} />
        </div>
      )}
    </Page>
  );
};

export default History;
