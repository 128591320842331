import { useLogin } from "hooks/fetch-hooks";
import React from "react";

/**
 * Higher Order Component (HOC) that ensures the wrapped component is rendered only if the user is signed in.
 *
 * This HOC uses the `useLogin` hook to check the `signedIn` state.
 * - If `signedIn` is `false`, it triggers the `logout` function and does not render the wrapped component.
 * - If `signedIn` is `true`, it renders the wrapped component with all passed props.
 *
 * @param {React.FC} Component - The React functional component to be wrapped.
 * @returns {React.FC} A new component that conditionally renders the passed component based on the user's signed-in status.
 * @example
 * const ProtectedComponent = withSignedIn(OriginalComponent);
 * // Now, the `ProtectedComponent` will only render if the user is signed in.
 */
const withSignedIn = (Component: React.FC) => {
  function WithSignedIn(props: any) {
    const { signedIn, logout } = useLogin();

    if (signedIn === false) {
      logout();
      return null;
    }

    return <Component {...props} />;
  }

  return WithSignedIn;
};

export default withSignedIn;
