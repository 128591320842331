import { Link, Typography } from "@mui/material";
import Page from "components/Page";
import PageTitle from "components/navigation/PageTitle";
import React from "react";

const Support: React.FC = () => (
  <Page>
    <PageTitle>Support</PageTitle>
    <Typography variant="h5" align="left" paragraph>
      Behöver du hjälp?
    </Typography>
    <Typography variant="body1" align="left" paragraph>
      Om du stöter på några problem eller har några frågor angående Gradelift, är du alltid
      välkommen att kontakta oss. Vi är här för att hjälpa till!
    </Typography>
    <Typography variant="h5" align="left" paragraph sx={{ mt: 4 }}>
      Vanliga frågor
    </Typography>

    <Typography variant="h6" align="left">
      Hur skapar jag ett konto?
    </Typography>
    <Typography variant="body1" align="left" paragraph>
      För att skapa ett konto, klicka på "Registrera" på startsidan och följ instruktionerna.
    </Typography>
    <Typography variant="h6" align="left">
      Hur ändrar jag mitt lösenord?
    </Typography>
    <Typography variant="body1" align="left" paragraph>
      Gå till ditt användarkonto, klicka på "Inställningar" och välj "Ändra lösenord".
    </Typography>
    <Typography variant="h6" align="left">
      Vad kostar tjänsten?
    </Typography>
    <Typography variant="body1" align="left" paragraph>
      Priserna varierar beroende på tjänsten. För detaljerad information, besök vår pris-sida.
    </Typography>
    <Typography variant="h5" align="left" paragraph sx={{ mt: 4 }}>
      Kontakt
    </Typography>
    <Typography variant="h6" align="left">
      E-post
    </Typography>
    <Typography variant="body1" align="left" paragraph>
      Du kan nå oss via e-post på{" "}
      <Link href="mailto:support@gradelift.se">support@gradelift.se</Link>. Vi svarar vanligtvis
      inom 24 timmar.
    </Typography>
    <Typography variant="h6" align="left">
      Telefon
    </Typography>
    <Typography variant="body1" align="left" paragraph>
      Vill du prata direkt med någon? Ring oss mellan 08:00 och 18:00 på{" "}
      <Link href="tel:0737684644">073-768 46 44</Link>.
    </Typography>
  </Page>
);

export default Support;
