import React, { useEffect, useState } from "react";
import RequestedLesson from "components/LessonDisplay/teacher-spec/RequestedLesson";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { useFetch } from "hooks/fetch-hooks";
import styles from "./flow.module.css";

const modes = {
  URGENT: {
    icon: faBars,
    mode: "urgent",
    filter: lesson => true,
  },
  PENDING: {
    icon: faPaperPlane,
    mode: "pending",
    filter: lesson => true,
  },
};

function Flow() {
  const [mode, setMode] = useState(modes.URGENT);
  const [fetchedReqs, setFetchedReqs] = useState([]);
  const { data: requests, isLoading: loading1 } = useFetch("/api/lesson/teacher/search", {
    authorize: true,
  });
  const { data: rejects } = useFetch("/api/lesson/teacher/rejected", { authorize: true });
  const [filteredReqs, setFilteredReqs] = useState([]);
  useEffect(() => {
    if (!requests) return;
    setFetchedReqs([...requests]);
  }, [requests]);
  useEffect(() => {
    if (!fetchedReqs) return;
    setFilteredReqs(fetchedReqs.filter(mode.filter));
  }, [mode, fetchedReqs, setFilteredReqs]);
  return (
    <section className="flow-section">
      <div className="d-flex flex-column align-items-center">
        <h1 className="f2 text-center">Elever som behöver hjälp</h1>
        <div className={`${styles.selector_parent} w-50 d-flex justify-content-center`}>
          {Object.values(modes).map(option => (
            <div
              key={option.mode}
              className={`${styles.selector} ${
                mode.mode === option.mode && styles.chosen
              } w-50 text-center`}
              onClick={() => setMode(option)}
            >
              <FontAwesomeIcon icon={option.icon} />
            </div>
          ))}
        </div>
      </div>

      <div className="mt-3">
        {filteredReqs.length
          ? filteredReqs.map((lesson, index) => (
              <RequestedLesson
                lesson={lesson}
                key={lesson.id}
                removeSelf={() =>
                  setFetchedReqs(prev => prev.filter(reqLesson => reqLesson.id !== lesson.id))
                }
              />
            ))
          : null}
      </div>
    </section>
  );
}

export default Flow;
