import { Box, Typography } from "@mui/material";
import React from "react";
import cx from "classnames";
import styles from "./Logo.module.css";

type LogoProps = { onClick?: () => void; onlyLogo?: boolean; color?: "gray" | "white" | "green" };

const Logo = ({ onClick, onlyLogo = false, color = "gray" }: LogoProps) => (
  <Box
    display="flex"
    columnGap={1}
    onClick={onClick}
    className={cx(styles.root, styles[`text-${color}`])}
  >
    {!onlyLogo && <Typography className={styles.text}>GradeLift</Typography>}
    <img className={cx(styles.logo, styles[`logo-${color}`])} src="/gl2.png" alt="logo" />
  </Box>
);

export default Logo;
