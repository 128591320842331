import { LoginContext } from "app";
import { Route, Routes } from "react-router-dom";
import { Typography } from "@material-ui/core";
import CustomContainer from "components/CustomContainer";
import Navigation, { adminLinks } from "components/navigation/admin-navigation";
import React from "react";

import AuthTeach from "./AuthorizeTeach";
import CreatePromo from "./Promo";

// The App component is a functional component that displays the admin view of the app
function App() {
  const { setSignedIn } = React.useContext(LoginContext);
  return (
    <CustomContainer>
      <Navigation />
      <div className="text-center">
        <Typography variant="h4">Admin vy</Typography>
      </div>
      <Routes>
        <Route path={adminLinks.AUTH} element={<AuthTeach />} />
        <Route path={adminLinks.PROMOS} element={<CreatePromo />} />
      </Routes>
      <div className="text-center" style={{ marginBottom: "4rem" }}>
        <button
          className="btn"
          onClick={_ => {
            localStorage.clear();
            setSignedIn(false);
          }}
        >
          Logga ut
        </button>
      </div>
    </CustomContainer>
  );
}

export default App;
