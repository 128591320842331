import { subscribeUser } from "services/notifications/push-subscription";

export async function showNotification() {
  const res = await Notification.requestPermission();
  if (res === "granted") {
    const noti = new Notification("Hello From GL App", {
      body: "I'm a push notification",
      icon: "/logo192.png",
    });
  }
}

export async function requestNotificationPermissions() {
  const res = await Notification.requestPermission();
  if (res !== "granted") return res;
  await subscribeUser();
  return res;
}
