export const customPost = async (
  url,
  data,
  { authorize = true, contentType = "application/json" },
) => {
  const headers = { "content-type": contentType };
  if (authorize) headers["Authorization"] = "Bearer " + localStorage.getItem("access_token");
  const fetchData = { method: "POST", headers, body: JSON.stringify(data) };
  return fetch(url, fetchData);
};
